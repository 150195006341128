import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DataService } from 'src/app/services/data.service';
import json_data from '../../../../assets/close_contact/close_contact_output.json'

@Component({
  selector: 'app-close-contact-graph',
  templateUrl: './close-contact-graph.component.html',
  styleUrls: ['./close-contact-graph.component.scss']
})
export class CloseContactGraphComponent implements OnInit {

  constructor(private jsonDataService: DataService) {
    this.json_src = this.jsonDataService.path;
  }

  ngOnInit(): void {
  }

  chartData: any = json_data;
  public json_src = "";
  clickEventsubscription = this.jsonDataService.getClickEvent().subscribe((scroll) => {
    this.updateChart(scroll);
  })

  /**
   * extracts the labels from the data
   * @returns labels
   */
  getLabelOfCase(case_name: string) {
    var chart_data = this.chartData[case_name]
    return Object.keys(chart_data[Object.keys(chart_data)[0]])
  }

  /**
   * sets the labels of the graph
   */
  populate_label(case_name: string) {
    this.lineChartLabels = this.getLabelOfCase(case_name);
  }

  /**
   * sets the data of the graph
   */
  populateDataSet(case_name: string) {
    if (case_name != "") {

      var chart_data = this.chartData[case_name]

      for (let i = 0; i < Object.keys(chart_data).length; i++) {
        this.lineChartData.push({
          data: (Object.values(chart_data[Object.keys(chart_data)[i]])).map(Number),
          label: Object.keys(chart_data)[i],
          fill: false
        })
      }
    }
  }

  // dataset for the chart
  public lineChartData: ChartDataSets[] = [];
  // labels for the chart
  public lineChartLabels: Label[] = [];
  // options for the chart
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          if (tooltipItem.yLabel >= 3200) {
            label = [[label], 'Exponiert']
          } else {
            label = [[label], 'Anfällig']
          }
          return label;
        },
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          maxTicksLimit: 20,
          callback: function (value, index, values) {
            return String(Number(value) / 60).split(".")[0];
          }
        },
        scaleLabel: {
          display: true,
          labelString: 'vergangene Zeit in Minuten',
          fontSize: 18
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Eingeatmete Pathogenlast',
          fontSize: 18
        }
      }],
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-1',
          value: 3200,
          borderColor: '#00FF00',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: '#00FF00',
            content: 'Exposed Threshold'
          }
        },
      ],
    }
  };

  // sets custom colors for the lines
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(255, 153, 51, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //orange
    {
      borderColor: 'rgba(255, 0, 0, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //red
    {
      borderColor: 'rgba(153, 102, 51, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //brown
    {
      borderColor: 'rgba(255, 160, 192, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //pink
    {
      borderColor: 'rgba(153, 0, 255, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //violet
    {
      borderColor: 'rgba(0, 51, 204, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //dark blue
    {
      borderColor: 'rgba(51, 204, 255, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //light blue
    {
      borderColor: 'rgba(51, 153, 51, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //dark green
    {
      borderColor: 'rgba(0, 255, 0, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //light green
    {
      borderColor: 'rgba(255, 255, 0, 1)',
      pointHoverBackgroundColor: '#fff'
    }, //yellow
    {
      borderColor: 'rgba(0, 0, 0, 1)',
      pointHoverBackgroundColor: '#fff',
    } //exposed Threshold - black
  ];
  public lineChartLegend = true;
  public lineChartType = 'line' as ChartType;

  /**
   * updates the chart data with the data corresponding to the current gif
   */
  updateChart(scroll) {
    this.lineChartData = []
    this.json_src = this.jsonDataService.path;
    if (this.json_src.split('.').pop() !== 'gif') {
      this.populateDataSet(this.json_src);
      this.populate_label(this.json_src);
      if (scroll) {
        this.scroll()
      }
    }
  }
  
  /**
   * scrolls to the chart
   */
  scroll() {
    document.getElementById('chart_')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

}
