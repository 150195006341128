import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  navLinks: any[];

  // links for the different scenarios
  links = ['Warteschlange', 'Enger-Kontakt', 'Museum', 'Bar'];
  activeLinkIndex = 0;
  activeLink: String;

  constructor(private router: Router) {     
  // construct the different router (links) for the scenarios
    this.navLinks = [
      { 
        label: this.links[0],
        link: './' + this.links[0].toLowerCase(),
        index: 0
      }, { 
        label: this.links[1],
        link: './' + this.links[1].toLowerCase(),
        index: 1
      }, { 
        label: this.links[2],
        link: './' + this.links[2].toLowerCase(),
        index: 2
      }, { 
        label: this.links[3],
        link: './' + this.links[3].toLowerCase(),
        index: 2
      },
    ];
    this.activeLink = this.navLinks[this.activeLinkIndex].link;
   }

  ngOnInit(): void {
    // set activeLinkIndex if tab is changed
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
  });
  }

  // background color is set to blue
  background: ThemePalette = 'primary';

}
