<div id="chart_" class="flex">
    <div class="flex-item">
      <div style="width:80%; height:20%; margin:0 auto;" >
        <h1 style="text-align: center;">
          inhalierte Pathogenlast über die Zeit
        </h1>
        <h3 style="text-align: center;">
          Bei Bedarf können einzelne Personen durch anklicken auf der Legende ausgeblendet werden.
        </h3>
      <canvas baseChart class="chart" 
                  [datasets]="lineChartData"
                  [labels]="lineChartLabels"
                  [options]="lineChartOptions"
                  [colors]="lineChartColors"
                  [legend]="lineChartLegend"
                  [chartType]="lineChartType">
                  (chartHover)="chartHovered($event)"</canvas>
      </div>
    </div>
  </div>