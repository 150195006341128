import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Options } from '@angular-slider/ngx-slider';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simulation-config-bar',
  templateUrl: './simulation-config-bar.component.html',
  styleUrls: ['./simulation-config-bar.component.scss']
})
export class SimulationConfigBarComponent implements OnInit {

  // sets the position of the first slider-pointer
  value1: number = 1;

  // options to create the first slider
  options1: Options = {
    floor: 1,
    ceil: 2,
    showTicks: true,
    showTicksValues: false,
    hideLimitLabels: true, 
    hidePointerLabels: true,
    getPointerColor: (value1: number): string => {
      if (value1 == 1) {
          return 'green';
      }
      if (value1 == 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    showSelectionBar: true,
    getSelectionBarColor: (value1: number): string => {
      if (value1 <= 1) {
        return 'green';
      }
      if (value1 <= 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    stepsArray: [{  
        value: 1,  
        legend: "<b>mit</b>"  
    }, {    
        value: 2,  
        legend: "<b>ohne</b>"  
    }],
  };

  // sets the position of the second slider-pointer
  value2: number = 2;

  // options to create the second slider
  options2: Options = {
    floor: 1,
    ceil: 2,
    showTicks: true,
    showTicksValues: false,
    hideLimitLabels: true, 
    hidePointerLabels: true,
    getPointerColor: (value2: number): string => {
      if (value2 == 1) {
          return 'green';
      }
      if (value2 == 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    showSelectionBar: true,
    getSelectionBarColor: (value2: number): string => {
      if (value2 <= 1) {
        return 'green';
      }
      if (value2 <= 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    stepsArray: [{  
        value: 1,  
        legend: "<b>schneller</b>"  
    }, {    
        value: 2,  
        legend: "<b>langsam</b>"  
    }],
  };

   // sets the position of the forth slider-pointer
  value3: number = 2;
 
  // options to create the forth slider
  options3: Options = {
    floor: 1,
    ceil: 2,
    showTicks: true,
    showTicksValues: false,
    hideLimitLabels: true, 
    hidePointerLabels: true,
    getPointerColor: (value4: number): string => {
      if (value4 == 1) {
          return 'green';
      }
      if (value4 == 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    showSelectionBar: true,
    getSelectionBarColor: (value4: number): string => {
      if (value4 <= 1) {
        return 'green';
      }
      if (value4 <= 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    stepsArray: [{  
        value: 1,  
        legend: "<b>infektiös</b>"  
    }, {    
        value: 2,  
        legend: "<b>hoch infektiös</b>"  
    }],
  };

  /**
   * Generates the gif-path with the given positions of the slider-pointer
   * @returns the path for the correct gif
   */
  public valueChange() {
    let val1 = this.value1
    if (val1 == 2){
      val1 = 0;
    }
    let val2 = this.value2
    if (val2 == 2){
      val2 = 0;
    }
    return "../../../assets/bar/Bar_Kell" + val1 + "_Infek" + (this.value3 - 1) + "_schB" + val2 + ".gif?" + Math.round(Math.random()*1000);
  } 

  constructor(
    private data: DataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.data.changeGif("../../../assets/scenario_start.svg")
  }

  /**
   * If "Simulation starten" button is pressed, the "changeGif" method is called
   */
  public runSimulation(): void {
    this.data.changeGif(this.valueChange())
    this.send_value(this.valueChange())
    this.scroll()
  }

  send_value(value: string){
    this.data.path = value.substring(value.lastIndexOf('/')+1).split('.')[0];
    this.router.navigate(["/line-chart-grafik"])
  }

  _updateChart(scroll: boolean) {
    this.data.sendClickEvent(scroll);
  }
  
  scroll() {
    document.getElementById('gif_')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

}
