<mat-drawer-container class="sidenav-container">
    <mat-drawer opened class="sidenav" mode="side">
      <app-simulation-config-bar></app-simulation-config-bar>
    </mat-drawer>
  
    <div class="sidenav-content">
      <div class="gif">
        <app-gif-loader-bar></app-gif-loader-bar>
      </div>
      <div>
        <app-color-scale></app-color-scale>
      </div>
      <div style="height:200px; width:100%; clear:both;"></div>
      <app-bar-graph></app-bar-graph>
      <div style="height: 50px; width:100%; clear:both;"></div>
    </div>
  </mat-drawer-container>