import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulation-view-enger-kontakt',
  templateUrl: './simulation-view-enger-kontakt.component.html',
  styleUrls: ['./simulation-view-enger-kontakt.component.scss']
})
export class SimulationViewEngerKontaktComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
