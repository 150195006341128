import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  private gif_src = new BehaviorSubject("../../../assets/scenario_start.png");
  currentGif = this.gif_src.asObservable();

  public path;

  private subject = new Subject<any>();

  constructor() { }

  changeGif(gif: string) {
    this.gif_src.next(gif)
  }

  sendClickEvent(scroll) {
    this.subject.next(scroll);
  }
  
  getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }

}
