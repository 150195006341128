import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-gif-loader-enger-kontakt',
  templateUrl: './gif-loader-enger-kontakt.component.html',
  styleUrls: ['./gif-loader-enger-kontakt.component.scss']
})
export class GifLoaderEngerKontaktComponent implements OnInit {

  constructor(private data: DataService) {
  }

  public gif_src;

  // if "Simulation starten" Button is pressed the gif gets loaded
  ngOnInit(): void {
    this.data.currentGif.subscribe(gif_src => this.gif_src = gif_src)
  }


}
