import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-color-scale',
  templateUrl: './color-scale.component.html',
  styleUrls: ['./color-scale.component.scss']
})
export class ColorScaleComponent implements OnInit {

  @ViewChild('canvas', { static: true })
  public canvas!: ElementRef<HTMLCanvasElement>;


  scaleBar: any[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.generateColor();
  }

  // helper function to get color for scale
  // returns 2 colors in a double array
  getScaleSet(index: number) {
    const colors = [
      // blue
      [36, 101, 214],
      // light blue (middle)
      [36, 211, 214],
      // green
      [108, 244, 20],
      // yellow
      [242, 238, 7],
      // red
      [209, 55, 8]
    ];

    // calculate the color-scale colors
    const split = (256 / 4);
    const alpha = (index % split) / (split);

    if (index < split) {
      return [colors[0], colors[1], alpha];

    } else if (index < (split * 2)) {
      return [colors[1], colors[2], alpha];

    } else if (index < split * 3) {
      return [colors[2], colors[3], alpha];

    } else {
      return [colors[3], colors[4], alpha];

    }
  }

  // generate the scale of color
  generateColor(): void {
    const colorCount = 255;
    let step = -127;

    for (let i = 0; i < colorCount + 1; i++) {
      
      const scaleSet = this.getScaleSet(i);
      const start = scaleSet[0];
      const end = scaleSet[1];
      const alpha = scaleSet[2] as number;

      const colors : number[] = [];

      colors[0] = end[0] * alpha + (1 - alpha) * start[0];
      colors[1] = end[1] * alpha + (1 - alpha) * start[1];
      colors[2] = end[2] * alpha + (1 - alpha) * start[2];

      // scale for plot
      this.scaleBar.push(
        {
          color: (`rgb(${colors[0]}, ${colors[1]}, ${colors[2]})`),
          value: step
        }
      );
      step++;
    }
  }

  update() {
    this.cdr.detectChanges();
  }

}
