import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SimulationViewComponent } from './components/queue/simulation-view/simulation-view.component';
import { HttpClientModule } from '@angular/common/http';
import { GifLoaderQueueComponent } from './components/queue/gif-loader-queue/gif-loader-queue.component';
import { SimulationConfigComponent } from './components/queue/simulation-config/simulation-config.component';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ColorScaleComponent } from './components/color-scale/color-scale.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { GifLoaderEngerKontaktComponent } from './components/enger-kontakt/gif-loader-enger-kontakt/gif-loader-enger-kontakt.component';
import { SimulationConfigEngerKontaktComponent } from './components/enger-kontakt/simulation-config-enger-kontakt/simulation-config-enger-kontakt.component';
import { SimulationViewEngerKontaktComponent } from './components/enger-kontakt/simulation-view-enger-kontakt/simulation-view-enger-kontakt.component';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { GifLoaderMuseumComponent } from './components/museum/gif-loader-museum/gif-loader-museum.component';
import { SimulationConfigMuseumComponent } from './components/museum/simulation-config-museum/simulation-config-museum.component';
import { SimulationViewMuseumComponent } from './components/museum/simulation-view-museum/simulation-view-museum.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { QueueGraphComponent } from './components/queue/queue-graph/queue-graph.component';
import { ChartsModule } from 'ng2-charts';
import { CloseContactGraphComponent } from './components/enger-kontakt/close-contact-graph/close-contact-graph.component';
import { MuseumGraphComponent } from './components/museum/museum-graph/museum-graph.component';
import { GifLoaderBarComponent } from './components/bar/gif-loader-bar/gif-loader-bar.component';
import { SimulationConfigBarComponent } from './components/bar/simulation-config-bar/simulation-config-bar.component';
import { SimulationViewBarComponent } from './components/bar/simulation-view-bar/simulation-view-bar.component';
import { BarGraphComponent } from './components/bar/bar-graph/bar-graph.component';

@NgModule({
  declarations: [
    AppComponent,
    SimulationViewComponent,
    GifLoaderQueueComponent,
    SimulationConfigComponent,
    ColorScaleComponent,
    TabsComponent,
    GifLoaderEngerKontaktComponent,
    SimulationConfigEngerKontaktComponent,
    SimulationViewEngerKontaktComponent,
    HomeMenuComponent,
    GifLoaderMuseumComponent,
    SimulationConfigMuseumComponent,
    SimulationViewMuseumComponent,
    LandingPageComponent,
    QueueGraphComponent,
    CloseContactGraphComponent,
    MuseumGraphComponent,
    GifLoaderBarComponent,
    SimulationConfigBarComponent,
    SimulationViewBarComponent,
    BarGraphComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatSliderModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    NgxSliderModule,
    MatTabsModule,
    ChartsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
