import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulation-view-museum',
  templateUrl: './simulation-view-museum.component.html',
  styleUrls: ['./simulation-view-museum.component.scss']
})
export class SimulationViewMuseumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
