<div class="scale-container">
    <div class="susceptible">Anfällig</div>    
    <div class="in-contact">Aufnehmend</div><!--/Ausgesetzt-->
    <div class="incubation">Exponiert</div>
    <div class="infected">Infiziert</div>
    <div class="scale">
      <svg
        *ngFor="let color of scaleBar; let idx=index"
        class="color"
        [style.backgroundColor]="color.color"
        attr.data-index={{idx}}
      >
      </svg>
    </div>
  </div>