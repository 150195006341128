import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SimulationViewComponent } from './components/queue/simulation-view/simulation-view.component';
import { SimulationViewEngerKontaktComponent } from './components/enger-kontakt/simulation-view-enger-kontakt/simulation-view-enger-kontakt.component';
import { HomeMenuComponent } from './components/home-menu/home-menu.component';
import { SimulationViewMuseumComponent } from './components/museum/simulation-view-museum/simulation-view-museum.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { SimulationViewBarComponent } from './components/bar/simulation-view-bar/simulation-view-bar.component'

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'tabs', component: TabsComponent, children: [ { 
    path: 'home-menu', component: HomeMenuComponent },
  { path: 'warteschlange', component: SimulationViewComponent },
  { path: 'enger-kontakt', component: SimulationViewEngerKontaktComponent },
  { path: 'museum', component: SimulationViewMuseumComponent },
  { path: 'bar', component: SimulationViewBarComponent }] },
  // { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
