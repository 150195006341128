import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Options } from '@angular-slider/ngx-slider';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simulation-config-enger-kontakt',
  templateUrl: './simulation-config-enger-kontakt.component.html',
  styleUrls: ['./simulation-config-enger-kontakt.component.scss']
})
export class SimulationConfigEngerKontaktComponent implements OnInit {
  
  // sets the position of the first slider-pointer
  value1: number = 1;

  // options to create the first slider
  options1: Options = {
    floor: 1,
    ceil: 3,
    showTicks: true,
    showTicksValues: false,
    hideLimitLabels: true, 
    hidePointerLabels: true,
    getPointerColor: (value1: number): string => {
      if (value1 == 1) {
          return 'green';
      }
      if (value1 == 2) {
          return 'orange';
      }
      if (value1 == 3) {
          return 'red';
      }
      return '#2AE02A';
    },
    showSelectionBar: true,
    getSelectionBarColor: (value1: number): string => {
      if (value1 <= 1) {
        return 'green';
      }
      if (value1 <= 2) {
          return 'orange';
      }
      if (value1 <= 3) {
          return 'red';
      }
      return '#2AE02A';
    },
    stepsArray: [{  
        value: 1,  
        legend: "<b>Mund/ Nase</b>"  
    }, {    
        value: 2,  
        legend: "<b>Mund</b>"  
    }, {  
        value: 3,  
        legend: "<b>keine</b>"  
    }],
  };

  // sets the position of the second slider-pointer
  value2: number = 2;

  // options to create the second slider
  options2: Options = {
    floor: 1,
    ceil: 3,
    showTicks: true,
    showTicksValues: false,
    hideLimitLabels: true, 
    hidePointerLabels: true,
    getPointerColor: (value2: number): string => {
      if (value2 == 1) {
          return 'green';
      }
      if (value2 == 2) {
          return 'orange';
      }
      if (value2 == 3) {
          return 'red';
      }
      return '#2AE02A';
    },
    showSelectionBar: true,
    getSelectionBarColor: (value2: number): string => {
      if (value2 <= 1) {
        return 'green';
      }
      if (value2 <= 2) {
          return 'orange';
      }
      if (value2 <= 3) {
          return 'red';
      }
      return '#2AE02A';
    },
    stepsArray: [{  
        value: 1,  
        legend: "<b>Mund/ Nase</b>"  
    }, {    
        value: 2,  
        legend: "<b>Mund</b>"  
    }, {  
        value: 3,  
        legend: "<b>keine</b>"  
    }],
  };

  // sets the position of the third slider-pointer
  value3: number = 2;

  // options to create the third slider
  options3: Options = {
    floor: 1,
    ceil: 2,
    showTicks: true,
    showTicksValues: false,
    hideLimitLabels: true, 
    hidePointerLabels: true,
    getPointerColor: (value3: number): string => {
      if (value3 == 1) {
          return 'green';
      }
      if (value3 == 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    showSelectionBar: true,
    getSelectionBarColor: (value3: number): string => {
      if (value3 <= 1) {
        return 'green';
      }
      if (value3 <= 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    stepsArray: [{  
        value: 1,  
        legend: "<b>7min</b>"  
    }, {    
        value: 2,  
        legend: "<b>15min</b>"  
    }],
  };

  // sets the position of the forth slider-pointer
  value4: number = 2;

  // options to create the forth slider
  options4: Options = {
    floor: 1,
    ceil: 2,
    showTicks: true,
    showTicksValues: false,
    hideLimitLabels: true, 
    hidePointerLabels: true,
    getPointerColor: (value4: number): string => {
      if (value4 == 1) {
          return 'green';
      }
      if (value4 == 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    showSelectionBar: true,
    getSelectionBarColor: (value4: number): string => {
      if (value4 <= 1) {
        return 'green';
      }
      if (value4 <= 2) {
          return 'red';
      }
      return '#2AE02A';
    },
    stepsArray: [{  
        value: 1,  
        legend: "<b>infektiös</b>"  
    }, {    
        value: 2,  
        legend: "<b>hoch infektiös</b>"  
    }],
  };

  /**
   * Generates the gif-path with the given positions of the slider-pointer
   * @returns the path for the correct gif
   */
  public valueChange() {

    var val1 = this.value1;
    // if the Mask is worn just over the mouth the same amount of pathogen is getting inhaled as without mask
    if (this.value1 == 3) {
      val1 = 2;
    }

    var val2 = this.value2;
    // if the Mask is worn just over the mouth the same amount of pathogen is getting exhaled as without mask
    if (this.value2 == 3) {
      val2 = 2;
    }

    // some constellations have the same outcome as others, so we use the same gif
    var val3 = this.value3;
    var val4 = this.value4;
    if ((val1 == 1 && val2 == 2 && val3 == 1 && val4 == 1) || (val1 == 1 && val2 == 2 && val3 == 2 && val4 == 1)) {
      val2 = 1;
      val4 = 2;
    }
    if ((val1 == 2 && val2 == 1 && val3 == 1 && val4 == 2) || (val1 == 2 && val2 == 1 && val3 == 2 && val4 == 2)) {
      val2 = 2;
      val4 = 1;
    }
    return "../../../assets/close_contact/Close_contact_maskS" + val1 + "_maskI" + val2 + "_dur" + val3 + "_infek" + val4 + ".gif?" + Math.round(Math.random()*1000);
  } 

  constructor(
    private data: DataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.data.changeGif("../../../assets/scenario_start.svg")
  }

  /**
   * If "Simulation starten" button is pressed, the "changeGif" method is called
   */
  public runSimulation(): void {
    this.data.changeGif(this.valueChange())
    this.send_value(this.valueChange())
    this.scroll()
  }

  send_value(value: string){
    this.data.path = value.substring(value.lastIndexOf('/')+1).split('.')[0];
    this.router.navigate(["/line-chart-grafik"])
  }

  _updateChart(scroll: boolean) {

    this.data.sendClickEvent(scroll);
  }
  
  scroll() {
    document.getElementById('gif_')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
}
