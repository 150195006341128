<mat-drawer-container class="sidenav-container">
  <mat-drawer opened class="sidenav" mode="side">
    <app-simulation-config></app-simulation-config>
  </mat-drawer>

  <div class="sidenav-content">
    <div class="gif">
      <app-gif-loader-queue></app-gif-loader-queue>
    </div>
    <div>
      <app-color-scale></app-color-scale>
    </div>
    <div style="height:200px; width:100%; clear:both;"></div>
    <app-queue-graph></app-queue-graph>
    <div style="height: 50px; width:100%; clear:both;"></div>
  </div>
</mat-drawer-container>
