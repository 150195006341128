<mat-drawer-container class="sidenav-container">
  <mat-drawer opened class="sidenav" mode="side">
    <app-simulation-config-enger-kontakt></app-simulation-config-enger-kontakt>
  </mat-drawer>

  <div class="sidenav-content">
    <div class="gif">
      <app-gif-loader-enger-kontakt></app-gif-loader-enger-kontakt>
    </div>
    <div>
      <app-color-scale></app-color-scale>
    </div>
    <div style="height:200px; width:100%; clear:both;"></div>
    <app-close-contact-graph></app-close-contact-graph>
    <div style="height: 50px; width:100%; clear:both;"></div>
  </div>
</mat-drawer-container>