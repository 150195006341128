<div class="config-container">
    <div class="slider-space"  style="text-align:center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">
      <span style="font-size: 20px; font-weight: bold;" >Anfälliger trägt Maske:</span> <br />
      <ngx-slider class="slider" [(value)]="value1" [options]="options1" (valueChange)="valueChange()"></ngx-slider>
    </div>  
    <div class="divider"></div>
    <div class="slider-space " style="text-align:center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">
      <span style="font-size: 20px; font-weight: bold;" >Infizierter trägt Maske:</span> <br />
      <ngx-slider class="slider" [(value)]="value2" [options]="options2" (valueChange)="valueChange()"></ngx-slider>
    </div>
    <div class="divider"></div>
    <div class="slider-space" style="text-align:center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">
      <span style="font-size: 20px; font-weight: bold;" >Aufenthaltsdauer:</span> <br />
      <ngx-slider class="slider" [(value)]="value3" [options]="options3" (valueChange)="valueChange()"></ngx-slider>
    </div>
    <div class="divider"></div>
    <div class="slider-space" style="text-align:center; margin-bottom: 10px; margin-left: 10px; margin-right: 10px;">
      <span style="font-size: 20px; font-weight: bold;" >Infektiösität:</span> <br />
      <ngx-slider class="slider" [(value)]="value4" [options]="options4" (valueChange)="valueChange()"></ngx-slider>
    </div>
  
    <div class="simulation-controll" style="align-self: center;">
      <!--runSimulation(); _updateChart(); runSimulation(); s.t. graph is updated even if "Grafik anzeigen" is not pressed-->
      <button
        color="primary"
        (click)="runSimulation(); _updateChart(false);"
        mat-raised-button
      >
        Simulation starten
      </button>
    </div>

    <div class="simulation-controll" style="align-self: center;">
      <button
        color="primary"
        (click)="runSimulation(); _updateChart(true);"
        mat-raised-button
      >
        Grafik anzeigen
      </button>
    </div>
    <img src="../../../assets/vadere-wbs.png">
  </div>
  