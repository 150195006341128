<!-- Intro Section -->

<div class="view" style="background-image: url(../../../assets/landing-page-picture.jpg); background-size: cover;">
  <a href="https://www.hm.edu/">
    <div class="hm-logo">
      <img src="../../../assets/Hochschule_Muenchen_Logo.svg.png" style="height: 20vh;">
    </div>
  </a>
  <div class="font-container">
    <h2>Covid-Simulation</h2>
    <h3 style="font-size:calc(8px + 1.3vw); line-height: calc(8px + 1.3vw)">Das CovidSim-Schieberegler-Tool visualisiert Computersimulationen der Übertragung 
      von Coronaviren in Menschenmengen. 
      Es wurde von Studierenden der Hochschule München in
      einem Projektstudium bei Prof. Gerta Köster entwickelt. 
      Das Tool hilft die Infektionsgefahr in Szenarien des Alltags zu verstehen: 
      z.B. in einer Warteschlange oder einer Bar. 
      Maßnahmen wie Maskentragen werden über Regler dazugeschaltet. 
      Man sieht ihre Wirkung direkt.
    </h3>
    <div>
      <a routerLink="/tabs/home-menu"><button mat-raised-button color="warn"
          style="font-size:25px; margin-bottom: 10px; ">Tool ausprobieren!</button></a>
      <a style="font-size: 20px; color: white; margin-bottom: 50px; text-decoration:none;"
        href="https://www.vadere.org/">
        &nbsp;&nbsp;&nbsp;<span style="text-decoration:underline">Weitere Infos
          zu Vadere</span></a>
    </div>
  </div>
  <a href="https://www.vadere.org/">
    <div class="vadere-logo">
      <img src="../../../assets/vadere-wbs-removebg-preview.png" style="height: 20vh">
    </div>
  </a>
</div>